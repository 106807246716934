import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Layout from '../components/app';
import gallerieListe from '../components/pages/gallery/gallerieListe';
import galleryDetails from '../components/pages/gallery/details';

export default function GalleryRoute() {
    return (
        <Switch>
            <Layout>
                <Route exact path={`${process.env.PUBLIC_URL}/gallery/:id/details`} component={galleryDetails}/>
                <Route exact path={`${process.env.PUBLIC_URL}/gallery/list`} component={gallerieListe}/>
            </Layout>
        </Switch>
    )
}