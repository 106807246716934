import {GET_MEMBERS} from "../constants/action-types";

const initialState = {
    members: [],
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MEMBERS:
            return {
                members: action.members
            };
        default:
            return state;
    }
};

export default categoryReducer;