import {combineReducers} from 'redux';

import blogReducer from "./blog";
import modalReducer from './modal';
import categoryReducer from "./categories";
import blogFilterReducer from "./postsFilters";
import eventReducer from "./events";
import galleryReducer from './gallery';
import membersReducer from "./members";



const rootReducer = combineReducers({
    blog: blogReducer,
    events: eventReducer,
    gallery: galleryReducer,
    blogFilters: blogFilterReducer,
    categories: categoryReducer,
    team: membersReducer,
    modal: modalReducer,
});

export default rootReducer;