import React from "react";
import Moment from "react-moment";
import 'moment/locale/fr';

const MomentLocaleMap = {
    ar: "ar-ma",
    en: "en-gb",
    es: "es",
    fr: "fr"
};

const langLoader = (lang) => import(`moment/locale/${MomentLocaleMap[lang]}`);

export const CMoment = (
    {
        date,
        locale,
        format = "LLLL",
        ...props
    }) => {

    return (
        <React.Fragment>
            {
                date ?
                    <Moment format={format} locale={locale} {...props}>{date}</Moment> :
                    ''
            }
        </React.Fragment>
    )
}

//https://momentjs.com/docs/#/durations/
