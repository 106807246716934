import React, {useEffect, useState} from 'react';
import {getAllGalleryPosts} from "../../../actions/galleryActions";
import {useDispatch} from "react-redux";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';


function galleryListe(props) {

    const dispatch = useDispatch();
    let galleries = props.galleries;

    let timer;
    const [loadedCount, setLoadedCount] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllGalleryPosts());

        if (galleries.length > 10) {
            setHasMore(true);
        } else {
            setHasMore(false);
        }
    }, [dispatch])

    function loadMore() {
        if (loadedCount < galleries.length) {
            setLoading(true);

            timer = setTimeout(() => {
                setLoadedCount(prevCount => prevCount + 10);
                setLoading(false);

                if (loadedCount >= galleries.length - 10) {
                    setHasMore(false);
                }
            }, 2000);
        } else {
            timer = setTimeout(() => {
                setHasMore(false);
            }, 500);
        }
    }

    return (
        <>

            <div className="theme-inner-banner section-spacing">
                <div className="overlay">
                    <div className="container">
                        <h2>Gallerie</h2>
                    </div>
                </div>
            </div>

            <div className="our-case our-project section-spacing">
                <div className="container">
                    <div className="wrapper">
                        <div className="row">
                            {
                                //    galleries && galleries.length > 0 ?
                                galleries.map((gal, index) =>
                                    <div key={index} className="col-lg-4 col-sm-6 col-12">
                                        <div className="single-case-block">
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/uploads/gallery_posts/${gal.fileName}`}
                                                alt=""/>
                                            <div className="hover-content">
                                                <div className="text clearfix">
                                                    <div className="float-left">
                                                        <h5 className='text-white'>{gal.title}</h5>
                                                        <p>{gal.createdAt}</p>
                                                    </div>
                                                    <Link to={`${process.env.PUBLIC_URL}/gallery/${gal.id}/details`}
                                                          className="details float-right">
                                                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                // : 'loading ...'
                            }

                        </div>
                    </div>
                    <div className="load-more-container text-center">
                        {
                            loadedCount < galleries.length ?
                                <button className="btn btn-outline-darker btn-load-more btn-primary"
                                        onClick={loadMore}>
                                    <span>Afficher plus d'albums </span>
                                    {
                                        loading ?
                                            <i className="icon-refresh load-more-rotating"></i> :
                                            <i className="icon-refresh"></i>
                                    }
                                </button>
                                : ''
                        }
                    </div>

                    {/* todo PAGINATION */}
                    {/*<div className="theme-pagination text-center">*/}
                    {/*    <ul>*/}
                    {/*        <li><a href="#">1</a></li>*/}
                    {/*        <li className="active"><a href="#">2</a></li>*/}
                    {/*        <li><a href="#"><i className="fa fa-angle-double-right" aria-hidden="true"></i></a></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
}


export const mapStateToProps = (state) => {
    return {
        galleries: state.gallery.galleryPosts ? state.gallery.galleryPosts : [],
    }
}

export default (connect)(mapStateToProps, {getAllGalleryPosts})(galleryListe);
