import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import LoadingOverlay from '../components/features/loading-overlay';
import {scrollTop} from '../utils';
import ErrorPage from "../components/pages/others/404";
import GalleryRoute from './gallery-route';

const HomePage = React.lazy(() => import( './home-route.js' ));
const BlogDetailsPage = React.lazy(() => import( './blogs-route.js' ));
const EventsPage = React.lazy(() => import( './events-route.js' ));
// const ErrorPage = React.lazy(() => import( '../components/pages/others/404' ));

function AppRoot(props) {

    useEffect(() => {
        scrollTop();
    }, [])


    return (
        <React.Suspense fallback={<LoadingOverlay/>}>
            <Switch>
                {/*<Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages}/>*/}
                <Route path={`${process.env.PUBLIC_URL}/blogDetails` } component={BlogDetailsPage} />
                <Route path={`${process.env.PUBLIC_URL}/events` } component={EventsPage} />
                <Route path={`${process.env.PUBLIC_URL}/gallery` } component={GalleryRoute} />
                <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                <Route component={ErrorPage} />
            </Switch>
        </React.Suspense>
    )
}

export default withRouter(AppRoot);