import axios from 'axios';
import {
    GET_GALLERY_POSTS,GET_GALLERY_DETAILS
} from "../constants/action-types";

import store from '../store';

export const getAllGalleryPosts = (p) => async dispatch => {


    let metadata = {
        "pagination": {
            "page": p,
        },
        "sort": {
            "field": "createdAt",
            "asc": false,
        },
        "filters": {
            "search": "",
        },
        "columns": {
            "*": [],
        }
    }


    const res = await axios.get(`${process.env.REACT_APP_API_URL}/gallery_posts`, {params: {meta: metadata}});


    dispatch({
        type: GET_GALLERY_POSTS,
        galleryPosts: res.data.entities
    });

}


export const getGalleryDetails = (id) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/gallery_posts/${id}`);

    dispatch({
        type: GET_GALLERY_DETAILS,
        galleryPostsDetails: res.data,
    });
}



