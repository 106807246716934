import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {getAllBlogPosts} from "../../actions/blogActions";
import {Link} from "react-router-dom";
import {CMoment} from "../helpers/moment";

function Footer(props) {
    let posts = props.posts;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllBlogPosts())
    }, [dispatch])


    return (
        <footer className="theme-footer-one">
            <div className="top-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-6 about-widget">
                            <h6 className="title">CNOM</h6>
                            <p> l’ordre national des médecins, cette institution est un organisme indépendant doté de la
                                personnalité morale qui regroupe obligatoirement tous les médecins exerçant la
                                profession médicale au Maroc dans le secteur privé et dans le secteur public.</p>
                            <div className="queries"><h6 className="title">SUIVEZ-NOUS :</h6><i
                                className="fa fa-facebook"></i><i className="fa fa-youtube"></i><i
                                className="fa fa-instagram"></i><i className="fa fa-linkedin"></i></div>
                        </div>
                        <div className="col-xl-4 col-lg-3 col-sm-6 footer-recent-post">
                            <h6 className="title">Dernières Actualités</h6>
                            <ul>
                                {
                                    posts && posts.length > 0 &&
                                    posts.slice(0, 2).map((post, index) =>
                                        <li className="clearfix" key={index}>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/uploads/posts/${post.fileName}`}
                                                alt="" className="float-left"/>

                                            <div className="post float-left">
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/blogDetails/${post.id}/detail`}
                                                    className="text-truncate">
                                                    {post.title}
                                                </Link>
                                                <div className="date">
                                                    <i className="fa fa-calendar-o" aria-hidden="true"></i>
                                                    <CMoment date={post.createdAt} format={"LLL"} locale={"fr"}/>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6 footer-list">
                            <h6 className="title">L’ORDRE</h6>
                            <ul>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/inscription`}>
                                        Inscription à l’ordre
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/inscription`}>
                                        Transfert d’inscription d’un secteur à un autre
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/inscription`}>
                                        Transfert d’inscription d’une région à une autre
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/inscription`}>
                                        Transfert d’inscription d’une région à une autre
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="col-xl-2 col-lg-2 col-sm-6 footer-list">
                            <h6 className="title">ADRESSES UTILES</h6>
                            <ul>
                                <li><a href="#">DELEGATION DE LA SANTE</a></li>
                                <li><a href="#">CHU</a></li>
                                <li><a href="#">HOPITAUX</a></li>
                                <li><a href="#">Centre de sante</a></li>
                                <li><a href="#">Bureau d'Hygiene</a></li>
                                <li><a href="#">Cliniques</a></li>
                                <li><a href="#">centre d'hemodialyze</a></li>
                                <li><a href="#">laboratoire d'analyse medicale</a></li>
                                <li><a href="#">centre d'hemodialyze</a></li>
                            </ul>
                        </div> */}
                        <div className="col-xl-2 col-lg-2 col-sm-6 footer-newsletter">
                            <h6 className="title">ABONNEMENT</h6>
                            <form action="#">
                                <input type="text" placeholder="Nom *"/>
                                <input type="email" placeholder="Email *"/>
                                <button className="theme-button-one">Je m'abonne</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12"><p>Copyright 2021. All Rights Reserved. © FOCUS AGENCY</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export const mapStateToProps = (state) => {
    return {
        posts: state.blog.posts ? state.blog.posts : [],
    }
}

export default (connect)(mapStateToProps, {getAllBlogPosts})(Footer);
