import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function Header(props) {
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <header className="header-one">
            <div className="top-header">
                <div className="top-header-infos"
                     style={{
                         backgroundColor: "#0B3D62",
                         color: "white",
                         width: "100%",
                         top: "0",
                         padding: "5px 10%"
                     }}>
                    <a className="header-top-meta" href="tel:+212 532 008 356">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        +212 532 008 356</a>
                    <a className="header-top-meta" href="" style={{color: "orange"}}>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        CROMORIENTAL@YAHOO.FR</a>
                    <a className="header-top-meta" href="">
                        <i class="fa fa-map-pin" aria-hidden="true"></i>
                        5 RUE CHEFCHAOUN, IMM.BENKACHOUR</a>
                </div>
                <div className="container clearfix"
                     style={{display: "flex", justifyContent: "center", paddingTop: "15px"}}>
                    <div className="logo float-left">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <img  src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} style={{'height':'84px'}} alt=""/>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="theme-menu-wrapper">
                <div className="container">
                    <div className="bg-wrapper clearfix" style={{display: "flex", justifyContent: "center"}}>
                        <div className="menu-wrapper float-left">
                            <nav id="mega-menu-holder" className="clearfix">
                                <ul className="clearfix">
                                    <li className="active">
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                            Accueil
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/about-us`}>
                                            À Propos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/events/list`}>
                                            Événements
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/gallery/list`}>
                                            Galerie
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/blog-list`}>
                                            Actualités
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/legislation`}>
                                            Législation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/inscription`}>
                                            Inscription
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        {/* <div className="right-widget float-right">
                            <ul>
                                <li className="search-option">
                                    <div className="dropdown">
                                        <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                                style={{color: "#000F32"}}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                        <form action="#" className="dropdown-menu" style={{color: "#000F32"}}>
                                            <input type="text" placeholder="Enter Your Search"/>
                                            <button><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
    )
}


export default Header;