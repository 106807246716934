import {
    GET_ALL_POSTS, GET_BLOG_POSTS_WITH_FILTERS,
    GET_POST_DETAILS, GET_POSTS_BY_CATEGORY
} from "../constants/action-types";

const initialState = {
    posts: [],
    filteredPosts: [],
    postsByCategory: [],
    postDetails: {},
    currentPage: 1,
    totalPages: 1,
};

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_POSTS:
            return {
                ...state,
                posts: action.posts
            };
        case GET_BLOG_POSTS_WITH_FILTERS:
            return {
                ...state,
                filteredPosts: action.filteredPosts,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_POSTS_BY_CATEGORY:
            return {
                ...state,
                postsByCategory: action.postsByCategory,
            };
        case GET_POST_DETAILS:
            return {
                ...state,
                postDetails: action.postDetails
            };

        default:
            return state;
    }
};

export default blogReducer;