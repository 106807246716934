import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {getGalleryDetails} from "../../../actions/galleryActions";
import {CMoment} from "../../helpers/moment";


function galleryDetails(props) {
    let post = props.galleryPost;
    let id = props.match.params.id;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGalleryDetails(id));
    }, [id, dispatch])

    return (
        post ?
            <>
                <div className="theme-inner-banner section-spacing">
                    <div className="overlay">
                        <div className="container">
                            <h2>{post.title}</h2>
                        </div>
                    </div>
                </div>
                <div className="project-details section-spacing">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-12 col-12 project-details-sidebar order-md-last"
                                 style={{
                                     'boxShadow': '0px 0px 9px 1px rgb(60 78 121 / 4%)',
                                     'borderRadius': '6px',
                                     'padding': '30px'
                                 }}>
                                <div className="strategies">
                                    <h3 className="main-title">{post.title}</h3>

                                    <p>{post.description}</p>

                                    <ul className="project-history clearfix">
                                        {
                                            post.createdBy &&
                                            <li>
                                                <h6 className="font-weight-bold"> Ajouté par :</h6>
                                                <span className="h6">
                                                    <i className="fa fa-user mr-2"></i>
                                                    {post.createdBy.firstName} {post.createdBy.lastName}
                                                </span>
                                            </li>
                                        }
                                        {
                                            post.createdAt &&
                                            <li>
                                                <h6 className="font-weight-bold">Date : </h6>
                                                <span className="h6">
                                                    <i className="fa fa-clock-o mx-1"></i>
                                                    <CMoment date={post.createdAt} format={"LL"}/>
                                                </span>
                                            </li>}
                                    </ul>
                                    <h6 className="font-weight-bold">Partager : </h6>
                                    <ul className="social-icon">
                                        <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-6 col-12 order-md-first image-col">

                                <img src={`${process.env.REACT_APP_API_URL}/uploads/gallery_posts/${post.fileName}`}
                                     alt=""/>

                                {
                                    post.images && post.images.length > 0 &&
                                    post.images.map((item, index) => {
                                            let ext = item.fileName.split(".");
                                            if (ext[ext.length - 1] === "mp4") {
                                                return (
                                                    <video width="600" height="300"
                                                           className="align-self-center mx-4"
                                                           style={{maxWidth: 100 + '%'}}
                                                           autoPlay muted controls>
                                                        <source
                                                            src={`${process.env.REACT_APP_API_URL}/uploads/gallery_post_attachments/${item.fileName}`}
                                                            type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )
                                            } else {
                                                return (
                                                    <img key={index}
                                                         className="align-self-center mx-4"
                                                         src={`${process.env.REACT_APP_API_URL}/uploads/gallery_post_attachments/${item.fileName}`}
                                                         alt=""/>
                                                )
                                            }
                                        }
                                        // <img src="images/portfolio/16.jpg" alt=""/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <p>Cet album n'existe plus. </p>
    );
}


export const mapStateToProps = (state) => {
    return {
        galleryPost: state.gallery.galleryPostsDetails ? state.gallery.galleryPostsDetails : {},
    }
}

export default (connect)(mapStateToProps, {getGalleryDetails})(galleryDetails);
