import {
    ADD_FILTER_BY_CATEGORY_SLUG,
    ADD_FILTER_BY_SEARCH_KEYWORD,
    RESET_BLOG_FILTERS,
    REFRESH_BLOG_FILTERS
} from '../constants/action-types'

const initialState = {
    category: "",
    search: ""
}

function blogFilterReducer(state = initialState, action) {
    switch (action.type) {

        case ADD_FILTER_BY_CATEGORY_SLUG:
            return {
                ...state,
                category: action.category
            };

        case ADD_FILTER_BY_SEARCH_KEYWORD:
            return {
                ...state,
                search: action.search
            };

        case RESET_BLOG_FILTERS:
            return {
                category: "",
                search: ""
            };

        case REFRESH_BLOG_FILTERS:
            return {state: initialState};

        default:
            return state;
    }
}

export default blogFilterReducer;