import {GET_CATEGORIES, GET_PARENT_CATEGORIES} from "../constants/action-types";

const initialState = {
    categories: [],
    parentCategories: []
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                categories: action.categories
            };
        case GET_PARENT_CATEGORIES:
            return {
                parentCategories: action.parentCategories
            };
        default:
            return state;
    }
};

export default categoryReducer;