import {
GET_GALLERY_POSTS, GET_GALLERY_DETAILS
} from "../constants/action-types";

const initialState = {
    galleryPosts: [],
    galleryPostsDetails: {},
    currentPage: 1,
    totalPages: 1,
};

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GALLERY_POSTS:
            return {
                ...state,
                galleryPosts: action.galleryPosts
            };
        case GET_GALLERY_DETAILS:
            return {
                ...state,
                galleryPostsDetails: action.galleryPostsDetails
            };

        default:
            return state;
    }
};

export default galleryReducer;