import {
    GET_ALL_EVENTS,
    GET_EVENT_DETAILS
} from "../constants/action-types";

const initialState = {
    events: [],
    eventDetails: {},
    currentPage: 1,
    totalPages: 1,
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_EVENTS:
            return {
                ...state,
                events: action.events
            };
        case GET_EVENT_DETAILS:
            return {
                ...state,
                eventDetails: action.eventDetails
            };

        default:
            return state;
    }
};

export default eventReducer;