import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import 'react-app-polyfill/ie11';
import './i18n/i18n';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './store';
import AppRoute from './routes';
import {initFunctions} from './utils';
import LoadingOverlay from './components/features/loading-overlay';
import {getAllBlogPosts} from "./actions/blogActions";

export function Root() {
    initFunctions();
    store.dispatch(getAllBlogPosts());


    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<LoadingOverlay/>}>
                <BrowserRouter basename={'/'}>
                    <AppRoute/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

ReactDOM.render(<Root/>, document.getElementById('root'));