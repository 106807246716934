// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NESLETTER';

//CONTACT_MESSAGES
export const SEND_CONTACT_MESSAGE = "SEND_CONTACT_MESSAGE";

//BLOG
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const GET_POSTS_BY_CATEGORY = "GET_POSTS_BY_CATEGORY";
export const GET_BLOG_POSTS_WITH_FILTERS = "GET_BLOG_POSTS_WITH_FILTERS";
//BLOG FILTER
export const ADD_FILTER_BY_CATEGORY_SLUG = "ADD_FILTER_BY_CATEGORY_SLUG";
export const ADD_FILTER_BY_SEARCH_KEYWORD = "ADD_FILTER_BY_SEARCH_KEYWORD";
export const RESET_BLOG_FILTERS = "RESET_BLOG_FILTERS";
export const REFRESH_BLOG_FILTERS = "REFRESH_BLOG_FILTERS";
//EVENTS
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_EVENT_DETAILS = "GET_EVENT_DETAILS";

//CATEGORIES
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PARENT_CATEGORIES = "GET_PARENT_CATEGORIES";


// GALLERY
export const GET_GALLERY_POSTS = "GET_GALLERY_POSTS";
export const GET_GALLERY_DETAILS = "GET_GALLERY_DETAILS";
// TEAM MEMBERS
export const GET_MEMBERS = "GET_MEMBERS";
