import axios from 'axios';
import {
    GET_ALL_POSTS, GET_POST_DETAILS, GET_POSTS_BY_CATEGORY,
    GET_BLOG_POSTS_WITH_FILTERS
} from "../constants/action-types";


import store from '../store';

export const getAllBlogPosts = (p) => async dispatch => {


    let metadata = {
        "pagination": {
            "page": p,
        },
        "sort": {
            "field": "createdAt",
            "asc": false,
        },
        "filters": {
            "search": "",
        },
        "columns": {
            "*": [],
            "categories": {
                "*": [],
            }
        }
    }


    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {params: {meta: metadata}});


    dispatch({
        type: GET_ALL_POSTS,
        posts: res.data.entities
    });

}


export const getPostDetails = (id) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/${id}`);

    dispatch({
        type: GET_POST_DETAILS,
        postDetails: res.data,
    });
}

// get posts by categoryId
export const getPostsByCategory = (id) => async dispatch => {

    const metadata = {
        "sort": {
            "field": "createdAt",
            "asc": false
        },
        "filters": {
            "search": "",
            "categories": {
                "id": id
            }
        },
        "columns": {
            "*": [],
            "categories": {
                "*": []
            }
        }
    }
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {params: {meta: metadata}});

    dispatch({
        type: GET_POSTS_BY_CATEGORY,
        postsByCategory: res.data.entities,
    });
}

export const getPostsWithFilters = (page) => async dispatch => {

    const blogFilters = store.getState().blogFilters;

    let metadata = {
        "pagination": {
            "page": page,
        },
        "sort": {
            "field": "createdAt",
            "asc": false
        },
        "filters": {
            "search": blogFilters.search ? blogFilters.search : "",
        },
        "columns": {
            "*": [],
            "categories": {
                "*": []
            }
        }
    }

    if (blogFilters.category) {
        metadata.filters.categories = {"slug": blogFilters.category}
    }

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {params: {meta: metadata}});

    dispatch({
        type: GET_BLOG_POSTS_WITH_FILTERS,
        filteredPosts: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}
